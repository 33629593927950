import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface LogoutFormProps {
    onLogout: () => void;
}

const LogoutForm = ({ onLogout }: LogoutFormProps) => {
    const { logout } = useAuth(); // Get the logout function from AuthContext
    const navigate = useNavigate();

    useEffect(() => {
        const performLogout = async () => {
            try {
                const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
             
                const response = await fetch(`${baseUrl}/auth/logout`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (response.ok) {
                    // Remove token from localStorage
                    localStorage.removeItem('authToken');
                    // Call the context logout to update the app state
                    logout();
                    // Trigger the external onLogout handler (from App.tsx)
                    onLogout();
                    // Redirect to the landing page
                    navigate('/', { replace: true });
                } else {
                    console.error('Failed to log out:', response.statusText);
                    // Even if the API fails, perform a local logout
                    logout();
                    navigate('/', { replace: true });
                }
            } catch (error) {
                console.error('Failed to log out:', error);
                // In case of an error, perform a local logout
                logout();
                navigate('/', { replace: true });
            }
        };

        performLogout();
    }, [logout, navigate, onLogout]);

    return <div>Logging you out...</div>;
};

export default LogoutForm;

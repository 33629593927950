import LogoutForm from '../components/LogoutForm'; // Import LogoutForm

interface LogoutPageProps {
    onLogout: () => void;
}

const LogoutPage = ({ onLogout }: LogoutPageProps) => {
    return (
        <div>
            <h2>Logging you out...</h2>
            {/* The LogoutForm will handle the actual logout logic */}
            <LogoutForm onLogout={onLogout} />
        </div>
    );
};

export default LogoutPage;

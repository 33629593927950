import { useState } from 'react';
import axios from 'axios';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handlePasswordReset = async () => {
        try {
            const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
            // Fetch the company associated with the user
            await axios.post(`${baseUrl}/auth/forgot-password`, { email });
            setMessage('A password reset link has been sent to your email.');
        } catch (error: any) {
            setMessage('Error: ' + error.message || 'An unknown error occurred.');
        }
    };

    return (
        <div>
            <h1>Forgot Password</h1>
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <button onClick={handlePasswordReset}>Send Reset Link</button>

            {message && <p>{message}</p>}
        </div>
    );
};

export default ForgotPasswordPage;

import { useState } from 'react';
import axios from 'axios';

const RegistrationForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [telephonePrefix, setTelephonePrefix] = useState('+1');
    const [telephone, setTelephone] = useState('');
    const [consentFunctional, setConsentFunctional] = useState(false);
    const [consentAnalytics, setConsentAnalytics] = useState(false);
    const [consentMarketing, setConsentMarketing] = useState(false);
    const [isConfirmPopupVisible, setConfirmPopupVisible] = useState(false); // State to toggle confirmation popup
    const [isSuccessPopupVisible, setSuccessPopupVisible] = useState(false); // State to toggle success popup
    const [message, setMessage] = useState('');

    // Show confirmation popup when the user clicks the initial submit
    const showConfirmPopup = () => {
        setConfirmPopupVisible(true);
    };

    // Go back to the form for updating data
    const handleUpdate = () => {
        setConfirmPopupVisible(false);
    };

    // Final submission to the server
    const handleFinalSubmit = async () => {
        const user1Data = {
            email,
            name,
            surname,
            telephone_prefix: telephonePrefix,
            telephone,
            password,
            consentCookies: {
                essential: true,    // Essential cookies are always true
                functional: consentFunctional,
                analytics: consentAnalytics,
                marketing: consentMarketing,
            }
        };

        try {
            const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
            await axios.post(`http://${baseUrl}/auth/onboard/user`, user1Data);
            setMessage(`An activation email has been sent to ${email}. Please check your inbox.`);
            setConfirmPopupVisible(false);  // Close the confirmation popup
            setSuccessPopupVisible(true);   // Open the success popup
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setMessage('Error: ' + error.response.data.message);
            } else if (error.message) {
                setMessage('Error: ' + error.message);
            } else {
                setMessage('An unknown error occurred');
            }
            setSuccessPopupVisible(false);
        }
    };

    return (
        <div>
            <h2>Register Account</h2>
            {message && <p>{message}</p>}
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <input
                type="text"
                placeholder="Enter your first name"
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <input
                type="text"
                placeholder="Enter your surname"
                value={surname}
                onChange={e => setSurname(e.target.value)}
            />
            <input
                type="text"
                placeholder="Enter telephone prefix"
                value={telephonePrefix}
                onChange={e => setTelephonePrefix(e.target.value)}
            />
            <input
                type="text"
                placeholder="Enter telephone number"
                value={telephone}
                onChange={e => setTelephone(e.target.value)}
            />
            <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />

            {/* Cookie consent checkboxes */}
            <div>
                <h3>Cookie Consent</h3>
                <div>
                    <input
                        type="checkbox"
                        checked={consentFunctional}
                        onChange={e => setConsentFunctional(e.target.checked)}
                    />
                    <label>Accept Functional Cookies</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        checked={consentAnalytics}
                        onChange={e => setConsentAnalytics(e.target.checked)}
                    />
                    <label>Accept Analytics Cookies</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        checked={consentMarketing}
                        onChange={e => setConsentMarketing(e.target.checked)}
                    />
                    <label>Accept Marketing Cookies</label>
                </div>
            </div>

            <button onClick={showConfirmPopup}>Submit</button>

            {/* Confirmation Popup */}
            {isConfirmPopupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Confirm Your Data</h3>
                        <p><strong>Email:</strong> {email}</p>
                        <p><strong>Name:</strong> {name}</p>
                        <p><strong>Surname:</strong> {surname}</p>
                        <p><strong>Telephone:</strong> {telephonePrefix} {telephone}</p>
                        <p><strong>Functional Cookies:</strong> {consentFunctional ? 'Accepted' : 'Declined'}</p>
                        <p><strong>Analytics Cookies:</strong> {consentAnalytics ? 'Accepted' : 'Declined'}</p>
                        <p><strong>Marketing Cookies:</strong> {consentMarketing ? 'Accepted' : 'Declined'}</p>
                        
                        <button onClick={handleUpdate}>Update</button>
                        <button onClick={handleFinalSubmit}>Submit</button>
                    </div>
                </div>
            )}

            {/* Success Popup */}
            {isSuccessPopupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Registration Complete</h3>
                        <p>An activation message has been sent to your email: {email}. Please check your inbox and click the link to activate your account.</p>
                        <button onClick={() => setSuccessPopupVisible(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegistrationForm;

import { useNavigate } from 'react-router-dom';
import CompanyForm from '../components/CompanyForm'; // Import the updated CompanyForm

const CompanyRegistrationPage = () => {
    const navigate = useNavigate();

    const handleCompanyRegistration = async (companyData: any) => {
        try {
            const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
            const response = await fetch(`http://${baseUrl}/profile/company/onboard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify(companyData),
            });

            if (response.ok) {
                alert('Company registered successfully!');
                navigate('/profile/company'); // Redirect after successful registration
            } else {
                const errorData = await response.json();
                alert(`Error registering company: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error registering company:', error);
            alert('Failed to register company. Please try again.');
        }
    };

    return (
        <div>
            <h1>Register Your Company</h1>
            <CompanyForm mode="add" onSubmit={handleCompanyRegistration} />
        </div>
    );
};

export default CompanyRegistrationPage;

// src/components/SettingsCookiesForm.tsx
import { useEffect, useState } from 'react';
import axios from 'axios';

interface CookiesSettings {
    essential: boolean;
    functional: boolean;
    analytics: boolean;
    marketing: boolean;
}

const SettingsCookiesForm = () => {
    const [cookies, setCookies] = useState<CookiesSettings>({
        essential: true,  // Essential cookies are always enabled
        functional: false,
        analytics: false,
        marketing: false,
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Fetch the current cookie settings from the server with headers
    useEffect(() => {
        const fetchCookieSettings = async () => {
            try {
                const token = localStorage.getItem('authToken');
                
                // Add the authorization headers
                const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
                const response = await axios.get<CookiesSettings>(`http://${baseUrl}/profile/user/cookies`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                
                setCookies(response.data);
                setMessage('Cookie settings fetched successfully.');
            } catch (error) {
                setError('Failed to fetch cookie settings. Please try again later.'+ (error as Error).message);
            }
        };
        fetchCookieSettings();
    }, []);

    // Handle checkbox changes
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setCookies(prev => ({ ...prev, [name]: checked }));
    };

    // Save the updated cookie settings with headers
    const handleSaveSettings = async () => {
        try {
            const token = localStorage.getItem('authToken');

            // Add the authorization headers
            await axios.post('http://localhost:3009/profile/user/cookies', cookies, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            
            setMessage('Settings saved successfully!');
            setError(''); // Clear any previous error messages
        } catch (error) {
            setError('Failed to save cookie settings. Please try again later.'+ (error as Error).message);
            setMessage(''); // Clear the success message
        }
    };

    return (
        <div>
            <h2>Cookie Settings</h2>
            {message && <p style={{ color: 'green' }}>{message}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="functional"
                        checked={cookies.functional}
                        onChange={handleCheckboxChange}
                    />
                    Functional Cookies
                </label>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="analytics"
                        checked={cookies.analytics}
                        onChange={handleCheckboxChange}
                    />
                    Analytics Cookies
                </label>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="marketing"
                        checked={cookies.marketing}
                        onChange={handleCheckboxChange}
                    />
                    Marketing Cookies
                </label>
            </div>
            <button onClick={handleSaveSettings}>Save Changes</button>
        </div>
    );
};

export default SettingsCookiesForm;

// src/components/Header.tsx

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import '../../assets/styles/Header.scss';  // Import the SCSS file

export default function Header() {
    const { user, logout } = useAuth() || {};
    const [hasCompany, setHasCompany] = useState(false);

    useEffect(() => {
        // Fetch company information only if the user is authenticated
        const fetchCompanyInfo = async () => {
            if (user) {
                const token = localStorage.getItem('authToken');
                try {
                    const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
                    const response = await axios.get(`${baseUrl}/profile/user/company`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    // If company info is available, show the company button
                    if (response.data) {
                        setHasCompany(true);
                    }
                } catch (error) {
                    setHasCompany(false); // No company associated or error fetching
                }
            }
        };

        fetchCompanyInfo();
    }, [user]);

    return (
        <header className="header">
            <nav className="navbar">
                <ul className="navbar-list">
                    {/* Home button always present */}
                    <li>
                        <Link className="nav-link" to="/">Home</Link>
                    </li>

                    {user ? (
                        <>
                            <li>Welcome, {user.name}</li>
                            <li>
                                <button className="auth-button" onClick={logout}>
                                    Logout
                                </button>
                            </li>
                            <li>
                                <Link className="nav-link" to="/profile">Profile</Link>
                            </li>
                            <li>
                                <Link className="nav-link" to="/profile/settings">Settings</Link>
                            </li>
                            {hasCompany && (
                                <li>
                                    <Link className="nav-link" to="/profile/company">Company</Link>
                                </li>
                            )}
                        </>
                    ) : (
                        <li>
                            <Link className="auth-button" to="/login">Login</Link>
                        </li>
                    )}
                </ul>
            </nav>
        </header>
    );
}

import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';  // Import AuthContext

interface LoginResponse {
    token: string;
    user: any;
}

interface LoginFormProps {
    onLogin: (token: string) => void;  // Define onLogin prop
}

const LoginForm = ({ onLogin }: LoginFormProps) => {
    const { login } = useAuth();  // Get the login function from AuthContext
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
            const response = await axios.post<LoginResponse>(`${baseUrl}/auth/login`, {
                email,
                password,
            });
            const { token, user } = response.data;

            // Store the token in localStorage
            localStorage.setItem('authToken', token);

            // Call the login function from context
            login(user);

            // Call the onLogin function passed from LoginPage
            onLogin(token);

            // Redirect to profile page
            navigate('/profile', { replace: true });
        } catch (error: any) {
            handleError(error);
        }
    };

    const handleError = (error: any) => {
        if (error.response && error.response.data && error.response.data.message) {
            setMessage('Error: ' + error.response.data.message);
        } else if (error.message) {
            setMessage('Error: ' + error.message);
        } else {
            setMessage('An unknown error occurred');
        }
    };

    return (
        <div className="login-form">
            <h1>Login to Your Account</h1>
            {message && <p>{message}</p>}
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
            <p>
                <Link to="/forgot-password">Forgot your password?</Link>
            </p>
        </div>
    );
};

export default LoginForm;

// src/features/ProfilePage.tsx
import { useState } from 'react';
import UserForm from '../components/UserForm';
import BottomBar from '../components/layout/BottomBar';

const ProfilePage = () => {
    const [userMode, setUserMode] = useState<'view' | 'edit'>('view');

    const toggleUserMode = () => {
        setUserMode(prevMode => (prevMode === 'view' ? 'edit' : 'view'));
    };

    
    return (
        <div className="profile">
            <h1>Your Profile</h1>
            <hr />

            {/* Toggle User Form Mode */}
            <button onClick={toggleUserMode}>
                {userMode === 'view' ? 'Edit User Info' : 'View User Info'}
            </button>
            <UserForm mode={userMode} />
            <hr />

          

        <BottomBar />

        </div>
    );
};

export default ProfilePage;

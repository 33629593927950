// NewsletterForm.tsx
import { useState } from 'react';
import axios from 'axios';

const NewsletterSubscriptionForm = () => {
    const [newsletterEmail, setNewsletterEmail] = useState('');
    const [newsletterFirstName, setNewsletterFirstName] = useState('');
    const [newsletterSurname, setNewsletterSurname] = useState('');
    const [newsletterCompany, setNewsletterCompany] = useState('');
    const [newsletterConsent, setNewsletterConsent] = useState(false);
    const [message, setMessage] = useState('');

    const handleNewsletter = async () => {
        try {
            const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
            await axios.post(`${baseUrl}/newsletter/subscribe`, {
                email: newsletterEmail,
                name: newsletterFirstName,
                surname: newsletterSurname,
                company: newsletterCompany,
                consent: newsletterConsent
            });
            setMessage(`Newsletter registered successfully for ${newsletterFirstName} ${newsletterSurname} with email: ${newsletterEmail}`);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setMessage('Error: ' + error.response.data.message);
            } else if (error.message) {
                setMessage('Error: ' + error.message);
            } else {
                setMessage('An unknown error occurred');
            }
        }
    };

    return (
        <div>
            <h2>Subscribe to our Newsletter</h2>
            {message && <p>{message}</p>}
            
            <input
                type="email"
                placeholder="Enter your email"
                value={newsletterEmail}
                onChange={e => setNewsletterEmail(e.target.value)}
            />
            
            <input
                type="text"
                placeholder="Enter your first name"
                value={newsletterFirstName}
                onChange={e => setNewsletterFirstName(e.target.value)}
            />
            
            <input
                type="text"
                placeholder="Enter your surname"
                value={newsletterSurname}
                onChange={e => setNewsletterSurname(e.target.value)}
            />
            
            <input
                type="text"
                placeholder="Enter your company"
                value={newsletterCompany}
                onChange={e => setNewsletterCompany(e.target.value)}
            />
            
            <div>
                <input
                    type="checkbox"
                    checked={newsletterConsent}
                    onChange={e => setNewsletterConsent(e.target.checked)}
                />
                <label>I agree to receive newsletters</label>
            </div>
            
            <button onClick={handleNewsletter}>Subscribe</button>
        </div>
    );
};

export default NewsletterSubscriptionForm;

// src/components/layout/BottomBar.tsx
import '../../assets/styles/BottomBar.scss';

const BottomBar = () => {
  return (
    <div className="bottom-bar">
      <p>If you represent a company, please register on the LauncherScanner portal.</p>
      <button onClick={() => window.location.href = '/profile/company/add'}>Register Company</button>
    </div>
  );
};

export default BottomBar;

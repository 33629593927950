import { useState, useEffect } from 'react';

const UserInfoUpdatePage = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [linkedIn, setLinkedIn] = useState<string>('');

    useEffect(() => {
        // Mock fetch user data
        setName('Test User');
        setEmail('testuser@example.com');
        setLinkedIn('https://linkedin.com/in/testuser');
    }, []);

    const handleUpdate = () => {
        // Handle update logic
        alert(`User updated: Name - ${name}, Email - ${email}, LinkedIn - ${linkedIn}`);
    };

    return (
        <div>
            <h1>Update User Information</h1>
            <div>
                <label>Name</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div>
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <label>LinkedIn</label>
                <input
                    type="text"
                    value={linkedIn}
                    onChange={(e) => setLinkedIn(e.target.value)}
                />
            </div>
            <button onClick={handleUpdate}>Update User Info</button>
        </div>
    );
};

export default UserInfoUpdatePage;

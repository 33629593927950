import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/CompanyForm.scss'; // Import the styles

interface CompanyFormProps {
    mode: 'view' | 'edit' | 'add';
    companyCode?: string;
    onSubmit?: (companyData: any) => Promise<void>;
}

const CompanyForm = ({ mode, companyCode }: CompanyFormProps) => {
    const [companyData, setCompanyData] = useState<any | null>(null);
    const [companyName, setCompanyName] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [zipcode, setZipcode] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [number, setNumber] = useState<string>('');
    const [company_code, setCompanyCode] = useState<string>(companyCode || '');
    const [industry, setIndustry] = useState<string>('');

    const navigate = useNavigate();

    useEffect(() => {
        if (mode !== 'add') {
            const token = localStorage.getItem('authToken');
            const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;

            let endpoint = companyCode
                ? `${baseUrl}/profile/company/${companyCode}`
                : `${baseUrl}/profile/user/company`;

            fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data) {
                        setCompanyData(data);
                        setCompanyName(data.name);
                        setCountry(data.country);
                        setState(data.state);
                        setCity(data.city);
                        setZipcode(data.zipcode);
                        setStreet(data.street);
                        setNumber(data.number);
                        setCompanyCode(data.company_code);
                        setIndustry(data.industry_id);
                    }
                })
                .catch((error) => console.error('Error fetching company data:', error));
        }
    }, [mode, companyCode]);

    const handleUpdateCompany = () => {
        navigate('/company/add');
    };

    const handleAddCompany = async () => {
        const newCompanyData = {
            name: companyName,
            country,
            state,
            city,
            zipcode,
            street,
            number,
            company_code,
            industry,
        };

        try {
            const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
            const response = await fetch(`${baseUrl}/profile/company/onboard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify(newCompanyData),
            });

            if (response.ok) {
                alert('Company added successfully!');
                navigate('/profile/company');
            } else {
                const errorData = await response.json();
                alert(`Error adding company: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error adding company:', error);
            alert('Failed to add company. Please try again.');
        }
    };

    return (
        <div className="company-form">
            <h2>{mode === 'add' ? 'Add Company Information' : 'Company Information'}</h2>
            {(companyData !== null || mode === 'add') ? (
                <div>
                    <div>
                        <label>Company Name:</label>
                        <input
                            type="text"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Country:</label>
                        <input
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>State:</label>
                        <input
                            type="text"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>City:</label>
                        <input
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Zipcode:</label>
                        <input
                            type="text"
                            value={zipcode}
                            onChange={(e) => setZipcode(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Street:</label>
                        <input
                            type="text"
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Number:</label>
                        <input
                            type="text"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Company Code:</label>
                        <input
                            type="text"
                            value={company_code}
                            onChange={(e) => setCompanyCode(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Industry ID:</label>
                        <input
                            type="text"
                            value={industry}
                            onChange={(e) => setIndustry(e.target.value)}
                            disabled={mode === 'view'}
                        />
                    </div>
                    {mode === 'edit' && (
                        <button onClick={handleUpdateCompany}>Update Company Info</button>
                    )}
                    {mode === 'add' && (
                        <button onClick={handleAddCompany}>Add Company</button>
                    )}
                </div>
            ) : (
                mode === 'view' && (
                    <div>
                        <p>No company information available.</p>
                        <button onClick={() => navigate('/profile/company/add')}>Add Company</button>
                    </div>
                )
            )}
        </div>
    );
};

export default CompanyForm;

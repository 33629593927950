import { useState, useEffect } from 'react';
import axios from 'axios';

import '../assets/styles/CompanyPage.scss'

interface CompanyData {
    name: string;
    country: string;
    city: string;
    state: string | null;
    street: string | null;
    number: string | null;
    zipcode: string | null;
    industry: string;
}

const CompanyPage = () => {
    const [company, setCompany] = useState<CompanyData | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
        // Fetch the company associated with the user
        axios.get(`${baseUrl}/profile/user/company`, {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            }
        })
        .then((response) => {
            setCompany(response.data as CompanyData);
        })
        .catch(() => {
            setMessage('Error fetching company information.');
        });
    }, []);

    return (
        <div className="company-page">
            <h1>Company Information</h1>
            {message && <p className="error-message">{message}</p>}

            {company ? (
                <div className="company-details">
                    <p><strong>Name:</strong> {company.name}</p>
                    <p><strong>Country:</strong> {company.country}</p>
                    <p><strong>City:</strong> {company.city}</p>
                    <p><strong>State:</strong> {company.state || 'N/A'}</p>
                    <p><strong>Street:</strong> {company.street || 'N/A'}</p>
                    <p><strong>Number:</strong> {company.number || 'N/A'}</p>
                    <p><strong>Zipcode:</strong> {company.zipcode || 'N/A'}</p>
                    <p><strong>Industry:</strong> {company.industry}</p>
                </div>
            ) : (
                <p>Loading company information...</p>
            )}
        </div>
    );
};

export default CompanyPage;

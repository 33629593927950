import { useParams } from 'react-router-dom';
import CompanyForm from '../components/CompanyForm';

const CompanyUpdatePage = () => {
    const { company_code } = useParams<{ company_code: string }>();

    return (
        <div>
            <h1>Update Company Information</h1>
            {company_code ? (
                <CompanyForm mode="edit" companyCode={company_code} />
            ) : (
                <p>Invalid or missing Company Code</p>
            )}
        </div>
    );
};

export default CompanyUpdatePage;

import LoginForm from '../components/LoginForm';  // Import LoginForm
import '../assets/styles/LoginPage.scss'

interface LoginPageProps {
    onLogin: (token: string) => void;
}

const LoginPage = ({ onLogin }: LoginPageProps) => {
    return (
        <div className="login-page">
            <LoginForm onLogin={onLogin} />  {/* Pass onLogin prop to LoginForm */}

        </div>
    );
};

export default LoginPage;





// src/App.tsx
import '../assets/styles/main.scss';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import RoutesComponent from '../components/RoutesComponent';  // Import your new component

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'));

    const handleLogin = (token: string) => {
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
    };

    useEffect(() => {
        setIsAuthenticated(!!localStorage.getItem('authToken'));
    }, []);

    return (
        <Router>
            <Header />
            <main className="main-content">
                {/* Pass business logic to RoutesComponent */}
                <RoutesComponent
                    handleLogin={handleLogin}
                    handleLogout={handleLogout}
                    isAuthenticated={isAuthenticated}
                />
            </main>
            <Footer />
        </Router>
    );
};

export default App;

import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const ActivateAccountPage = () => {
    const [invitationCode, setInvitationCode] = useState('');
    const [message, setMessage] = useState('');

    // Extract the token from the query parameters
    const location = useLocation();

    // Function to extract the token from the URL
    const getTokenFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('token') || '';
    };

    const [token, setToken] = useState(getTokenFromUrl());

    useEffect(() => {
        // Update token state if the URL changes
        setToken(getTokenFromUrl());
    }, [location]);

    const handleActivation = async () => {
        try {
            const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
            await axios.post(`${baseUrl}/auth/activate/user`, {
                token,
                invitation_code: invitationCode
            });
            setMessage('Account activated successfully!');
        } catch (error: any) {
            setMessage('Error activating account: ' + error.response?.data?.message || error.message);
        }
    };

    return (
        <div>
            <h1>Activate Your Account</h1>
            <p>We have extracted your token from the URL. Please enter your invitation code to complete the activation process.</p>

            <input
                type="text"
                placeholder="Enter invitation code"
                value={invitationCode}
                onChange={e => setInvitationCode(e.target.value)}
            />
            <button onClick={handleActivation}>Activate</button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ActivateAccountPage;

import '../../assets/styles/Footer.scss'


// src/components/Layout/Footer.tsx
export default function Footer() {
    return (
      <footer>
        <div className="footer-left">
          <h4>Our Premises</h4>
          <p>Address details go here...</p>
        </div>
        <div className="footer-center">
          <h4>Contact Us</h4>
          <p>Email: info@company.com</p>
          <p>Phone: +123 456 7890</p>
        </div>
        <div className="footer-right">
          <h4>Other Information</h4>
          <p>Some other information here...</p>
        </div>
      </footer>
    );
  }
  
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from '../features/LandingPage';
import ProfilePage from '../features/ProfilePage';
import ActivateAccountPage from '../features/ActivateAccountPage';
import NotFoundPage from '../features/NotFoundPage';
import ForgotPasswordPage from '../features/PasswordResetPage';
import LoginPage from '../features/LoginPage';
import LogoutPage from '../features/LogoutPage';
import UserInfoUpdatePage from '../features/UserInfoUpdatePage';
import UserSettingsPage from '../features/UserSettingsPage';
import CompanyUpdatePage from '../features/CompanyUpdatePage';
import CompanyAddPage from '../features/CompanyRegistrationPage';
import CompanyPage from '../features/CompanyPage'; // Import CompanyPage

interface RoutesComponentProps {
    handleLogin: (token: string) => void;
    handleLogout: () => void;
    isAuthenticated: boolean;
}

const RoutesComponent = ({ handleLogin, handleLogout, isAuthenticated }: RoutesComponentProps) => {
    return (
        <Routes>
            <Route path="/register-company" element={<CompanyAddPage />} />
            <Route path="/" element={isAuthenticated ? <Navigate to="/profile" /> : <LandingPage />} />
            <Route path="/login" element={isAuthenticated ? <Navigate to="/profile" /> : <LoginPage onLogin={handleLogin} />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/profile" element={isAuthenticated ? <ProfilePage /> : <Navigate to="/login" />} />
            <Route path="/profile/company" element={isAuthenticated ? <CompanyPage /> : <Navigate to="/login" />} /> {/* New Route */}
            <Route path="/activate-account/user" element={<ActivateAccountPage />} />
            <Route path="/logout" element={<LogoutPage onLogout={handleLogout} />} />
            <Route path="/profile/update" element={isAuthenticated ? <UserInfoUpdatePage /> : <Navigate to="/login" />} />
            <Route path="/profile/settings" element={isAuthenticated ? <UserSettingsPage /> : <Navigate to="/login" />} />
            <Route path="/profile/company/update" element={isAuthenticated ? <CompanyUpdatePage /> : <Navigate to="/login" />} />
            <Route path="/profile/company/add" element={isAuthenticated ? <CompanyAddPage /> : <Navigate to="/login" />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default RoutesComponent;

import { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/styles/UserForm.scss'; // Import the styles

interface UserData {
    name: string;
    surname: string;
    email: string;
    telephone_prefix: string | null;
    telephone: string | null;
    linkedIn: string | null;
    bio: string | null;
    account_id: number | null;
}

interface UserFormProps {
    mode: 'view' | 'edit';
}

const UserForm = ({ mode }: UserFormProps) => {
    const [userData, setUserData] = useState<UserData | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
        // Fetch user data from API
        fetch(`http://${baseUrl}/profile/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => setUserData(data))
            .catch((error) => console.error('Error fetching user data:', error));
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData!,
            [name]: value,
        }));
    };

    const handleSaveChanges = async () => {
        if (userData) {
            const token = localStorage.getItem('authToken');

            try {
                await axios.put('http://localhost:3009/profile/user', userData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setMessage('User data updated successfully.');
            } catch (error) {
                setMessage('Error updating user data.');
            }
        }
    };

    return (
        <div className="user-form">
            <h2>User Information</h2>
            {message && <p className="error-message">{message}</p>}

            {userData && (
                <div>
                    <div>
                        <label>Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={userData.name}
                            onChange={handleChange}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Surname:</label>
                        <input
                            type="text"
                            name="surname"
                            value={userData.surname}
                            onChange={handleChange}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={userData.email}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <label>Telephone Prefix:</label>
                        <input
                            type="text"
                            name="telephone_prefix"
                            value={userData.telephone_prefix || ''}
                            onChange={handleChange}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Telephone:</label>
                        <input
                            type="text"
                            name="telephone"
                            value={userData.telephone || ''}
                            onChange={handleChange}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>LinkedIn:</label>
                        <input
                            type="text"
                            name="linkedIn"
                            value={userData.linkedIn || ''}
                            onChange={handleChange}
                            disabled={mode === 'view'}
                        />
                    </div>
                    <div>
                        <label>Bio:</label>
                        <textarea
                            name="bio"
                            value={userData.bio || ''}
                            onChange={handleChange}
                            disabled={mode === 'view'}
                        />
                    </div>

                    {mode === 'edit' && (
                        <button onClick={handleSaveChanges}>Save Changes</button>
                    )}
                </div>
            )}
        </div>
    );
};

export default UserForm;

import NewsletterSubscriptionForm from '../components/NewsletterSubscriptionForm';
import RegistrationForm from '../components/RegistrationForm';
import BottomBar from '../components/layout/BottomBar';

const LandingPage = () => {
    return (
        <div className="landing-page-container">
            <div className="landing-content">
                <h1>Welcome to Our Platform</h1>

                {/* Newsletter Registration */}
                <NewsletterSubscriptionForm />

                {/* User Registration */}
                <RegistrationForm />
            </div>

            <BottomBar /> {/* Add BottomBar right above Footer */}
        </div>
    );
};

export default LandingPage;

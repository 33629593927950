// src/features/UserSettingsPage.tsx
import SettingsCookiesForm from '../components/SettingsCookiesForm';

const UserSettingsPage = () => {
    return (
        <div>
            <h1>User Settings</h1>
            <SettingsCookiesForm />
            {/* You can add other settings forms here as well */}
        </div>
    );
};

export default UserSettingsPage;
